<template>
  <div class="dis-con">
    <div class="top-banner">
      <img src="https://test.ewei.shop/static/images/apps/commission/apply_banner.png" alt="">
    </div>
    <div class="no-condition" v-if="code == 1001">
      <CellGroup v-if="info.full_info == 1 ">
        <Field required v-model="input_obj.first_field" :label="FieldArr[0]" v-if="FieldArr[0]"></Field>
        <Field required v-model="input_obj.second_field" :label="FieldArr[1]" v-if="FieldArr[1]"></Field>
        <Field required v-model="input_obj.third_field" :label="FieldArr[2]" v-if="FieldArr[2]"></Field>
        <Field required v-model="input_obj.fourth_field" :label="FieldArr[3]" v-if="FieldArr[3]"></Field>
        <Field required v-model="input_obj.fifth_field" :label="FieldArr[4]" v-if="FieldArr[4]"></Field>
      </CellGroup>
      <Checkbox v-if="info.show_text == 1" class="text-con" v-model="checked_show">
        <span>我已阅读并了解</span>
        <span class="xieyi-text" @click.stop="show_Dialog = true">《分销商协议》</span>
      </Checkbox>
      <div v-if="info.full_info == 1 " class="push-btn" @click="add_disuser">
        <Button round color="#fa512d" type="primary" block> 成为分销商 </Button>
      </div>
      <div v-else class="push-btn">
        <Button round color="#fa512d" to="/vipShopList" type="primary" block> 去加油 </Button>
      </div>
    </div>

    <template v-if="code == 1002">
      <div class="no-condition">
        <h2 class="entry-title">当前状态 {{ info.apply.status | text_status }}</h2>
        <div class="refuse_reason" v-if="info.apply.status == 2">
          <span>填写信息:</span>
          <span v-if="info.apply.first_field">{{ info.apply.first_field }}</span> 
          <span class="res-text" v-if="info.apply.second_field">{{ info.apply.second_field }}</span>
          <span class="res-text" v-if="info.apply.third_field">{{ info.apply.third_field }}</span>
          <span class="res-text" v-if="info.apply.fourth_field">{{ info.apply.fourth_field }}</span> 
          <span class="res-text" v-if="info.apply.fifth_field">{{ info.apply.fifth_field }}</span>
        </div>
        <div class="refuse_reason" v-if="info.apply.status == 2">
          <span>驳回原因:</span>
          <span>{{ info.apply.refuse_reason }}</span>
        </div>
        <div class="refuse_reason">
          <span>创建时间:</span>
          <span>{{ info.apply.create_time }}</span>
        </div>
      </div>
      <div class="no-condition" v-if="info.apply.status == 2">
        <CellGroup>
          <Field required v-model="input_obj.first_field" :label="FieldArr[0]" v-if="FieldArr[0]"></Field>
          <Field required v-model="input_obj.second_field" :label="FieldArr[1]" v-if="FieldArr[1]"></Field>
          <Field required v-model="input_obj.third_field" :label="FieldArr[2]" v-if="FieldArr[2]"></Field>
          <Field required v-model="input_obj.fourth_field" :label="FieldArr[3]" v-if="FieldArr[3]"></Field>
          <Field required v-model="input_obj.fifth_field" :label="FieldArr[4]" v-if="FieldArr[4]"></Field>
        </CellGroup>
        <Checkbox v-if="info.show_text == 1" class="text-con" v-model="checked_show">
          <span>我已阅读并了解</span>
          <span class="xieyi-text" @click.stop="show_Dialog = true">《分销商协议》</span>
        </Checkbox>

        <Overlay :show="show_Dialog">
          <div class="info-con">
            <span class="info-con-title">{{ info.text.title }}</span>
            <div class="info-all" v-html="info.text.content"></div>
            <div class="my-btn">
              <Button @click="show_Dialog = false" round color="#fa512d" type="primary" block >我知道了</Button>
            </div>
          </div>
        </Overlay>
        <div class="push-btn" @click="add_disuser">
          <Button round color="#fa512d" type="primary" block > {{ info.apply.status == 2 ? '继续提交':'成为分销商' }}  </Button>
        </div>
      </div>
    </template>

    <div v-if="code == 1003" class="other-content">
      <div class="other-content-top">
        <p>您已累计消费</p>
        <p>{{info.user_custom_num}} <span class="ci-text">次</span></p>
      </div>
      <p class="xiaofei-text">本店累计消费满 <span class="num-ci">{{info.custom_num}}</span> 次, 即可成为分销商</p>
      <div class="push-btn">
        <Button round to="/vipShopList" color="#fa512d" type="primary" block > 去加油 </Button>
      </div>
    </div>

    <div v-if="code == 1004" class="other-content">
      <div class="other-content-top">
        <p>您已累计消费</p>
        <p>{{info.user_custom_amount}} <span class="ci-text">元</span></p>
      </div>
      <p class="xiaofei-text">本店累计消费满 <span class="num-ci">{{info.custom_amount}}</span> 元, 即可成为分销商</p>
      <div class="push-btn">
        <Button round to="/vipShopList" color="#fa512d" type="primary" block > 去加油 </Button>
      </div>
    </div>
    <div v-if="code == 1005" class="other-content">
      <div class="other-content-top">
        <p>需要购买油品</p>
        <p>{{info.user_buy_goods}} <span class="ci-text"></span></p>
      </div>
      <p class="xiaofei-text">本店需购买 <span class="num-ci">{{info.buy_goods_arr}}</span> 油品, 即可成为分销商</p>
      <div class="push-btn">
        <Button to="/vipShopList" round color="#fa512d" type="primary" block > 去加油 </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, CellGroup, Button, Checkbox, Overlay } from 'vant';
import UserInfo from "@/api/user";
export default {
  components: {
    Field, CellGroup, Button, Checkbox, Overlay
  },
  data() {
    return {
      code: "",
      show_Dialog: false,
      info: {
        text: {}
      },
      checked_show: false,
      FieldArr: [],
      input_obj: {
        first_field: "",
        second_field: "",
        third_field: "",
        fourth_field: "",
        fifth_field: ""
      }
    }
  },
  filters: {
    text_status: function(value) {
      switch (value) {
        case 0:
          return "待审核"
        case 1:
          return "审核通过"        
        case 2:
          return "审核驳回"
        default:
          break;
      }
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    set_check() {

    },
    async add_disuser() {
      try {
        if (this.info.show_text == 1) {
          if (!this.checked_show) {
            return this.$toast("请勾选协议内容");
          }
        }
        const res = await UserInfo.openSpread(
          this.input_obj.first_field, this.input_obj.second_field, this.input_obj.third_field, this.input_obj.fourth_field, this.input_obj.fifth_field);
        this.$toast(res.msg);
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 200已经是分销商直接进入主页面 
     * 400分销商功能未开启 
     * 1001需要申请分销商 
     * 1002已申请分销商有申请状态，状态为审核中，审核驳回，如果审核驳回有驳回原因 
     * 1003需要消费次数 
     * 1004需要消费金额 
     * 1005需要购买油品
     */
    async getDetail() {
      try {
        const res = await UserInfo.getSpreadSetInfo();
        this.code = res.code;
        switch (res.code) {
          case 200:
            // this.$router.push({ path: '/oil_spread' });
            break;
          case 400:
            this.$toast('分销功能未开启');
            break;
          case 1001:
            this.FieldArr = res.data.info_field.split(',');
            this.info = res.data;
            break;
          case 1002:
            this.FieldArr = res.data.info_field;
            this.info = res.data;
            break;
          case 1003:
            this.info = res.data;
            break;
          case 1004:
            this.info = res.data;
            break;
          case 1005:
            var result = res.data;
            if (result.user_buy_goods.length > 0) result.user_buy_goods = result.user_buy_goods.join(',');
            else result.user_buy_goods = "尚未购买油品";
            if (result.buy_goods_arr.length > 0) result.buy_goods_arr = result.buy_goods_arr.join(',');
            if (result.need_buy_goods.length> 0) result.need_buy_goods = result.need_buy_goods.join(',');
            this.info = result;
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getInfoField() {
      try {
        const res = await UserInfo.getSpreadInfoField();
        if (res.code == 200) {
          this.FieldObj = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
}
</script>
<style scoped>
.top-banner {
  width: 100%;
  height: 160px;
}
.top-banner img {
  width: 100%;
  display: block;
}
.dis-con {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.no-condition {
  width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 6px;
  padding-bottom: 10px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
}

/* .no-condition:nth-child(2) {
  margin-top: 40px;
} */
.push-btn {
  padding: 0 10px;
  margin-top: 20px;
}
.entry-title {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 560;
  line-height: 40px;
  padding-left: 10px;
}
.refuse_reason {
  width: 100%;
  height: 30px;
  font-size: 14px;
  padding: 0 10px;
}
.res-text {
  margin-left: 10px;
}
.my-html {
  width: 100%;
  padding-left: 20px;
}
.text-con {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 16px;
}

.xieyi-text {
  color: #fa512d;
}
.push-btn {
  padding: 0 10px;
  margin-top: 20px;
}
.info-con {
  width: 80%;
  height: 300px;
  position: relative;
  border-radius: 6px;
  margin: 140px auto 0;
  overflow-y: auto;
  background-color: #fff;
}
.info-con-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid #fdfdfd;
}
.info-all {
  width: 100%;
  padding: 20px;
}
.my-btn {
  width: 250px;
  left: 50%;
  bottom: 20px;
  margin-left: -125px;
  position: absolute;
}
.other-content {
  width: 100%;
  height: 290px;
  padding-bottom: 20px;
  background-color: #fff;
}
.other-content-top {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px 40px; 
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  box-shadow: 0 0 20px 4px #f2f2f2;
  margin: 24px auto 10px;
}
.other-content-top p:nth-child(2) {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 700;
  color: #363636;
}
.ci-text {
  font-size: 14px;
  font-weight: 600;
}
.num-ci {
  color: #fa512d;
}
.xiaofei-text {
  width: 100%;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  color: #363636;
}
</style>